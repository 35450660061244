<template>
  <div class="option-container" role="tablist">
    <div
      class="option-container__info"
      @click="collapse()"
      v-b-toggle="'collapse' + carrier._id"
      role="tab"
    >
      <div class="image-container">
        <img
          class="image-container__image"
          :src="carrierImage.url"
          :alt="carrier.carrier"
        />
      </div>
      <div class="option-container__info__description">
        <div class="option-container__item option-container__item--restrictions">
          <span> {{ $t("carriers.transitTime") }}: {{ setTransitTime }} </span>
          <p class="option-container__performance">{{ $t("carriers.performance") }}:</p>
          <p>
            Pickup:&nbsp;
            <span class="option-container__item--percentage">
              {{ Math.ceil(carrier.pickupPerformance) }}%</span
            >
            &nbsp;/&nbsp;Delivery:&nbsp;
            <span class="option-container__item--percentage"
              >{{ Math.ceil(carrier.deliveryPerformance) }}%</span
            >
          </p>
        </div>
      </div>
      <div class="collapse-button">
        <button class="collapse-button__button" :class="isCollapsed">
          <i
            class="ion ion-chevron-forward-outline collapse-button__icon collapse-icon"
          ></i>
        </button>
      </div>
    </div>
    <b-collapse
      :id="'collapse' + carrier._id"
      class="option-container__collapse"
      accordion="my-accordion"
      role="tabpanel"
    >
      <div>
        <span class="option-container__collapse__title-liability">
          <strong class="mr-1">Service class:</strong>
          {{ carrier.serviceClass }}
        </span>
        <span class="option-container__collapse__title-liability">
          <strong class="mr-1">Quote Expiration Date:</strong>
          {{ carrier.expiration | dateFormat }}
        </span>
        <span
          v-if="carrier.alert"
          v-b-popover.hover="carrier.description"
          class="warning-alert-icon"
        >
          <i class="ion ion-warning-outline"></i>
          Tap to see service threshold
        </span>
      </div>
    </b-collapse>
    <div
      class="option-container__item option-container__book-buttons"
      v-if="!$options.filters.checkPermission('post:tender')"
    >
      <div class="carrier-price">
        <span> <b>PRICE</b> {{ carrier.price | priceToUsd }}</span>
      </div>
      <div class="carrier-insurance-price">
        <span>
          <b>PRICE WITH INSURANCE</b>
          {{ (carrier.price + aditionalInsurance).toFixed(2) | priceToUsd }}</span
        >
      </div>
    </div>
    <div
      class="option-container__item option-container__book-buttons"
      v-if="$options.filters.checkPermission('post:tender')"
    >
      <button
        class="quote-button quote-button--blue"
        @click="selectedCarrier(false, carrier)"
      >
        <div class="quote-button__text" v-if="!loadingInsurance">
          <span>{{ $t("carriers.book") }}</span>
          <span>USD{{ carrier.price.toFixed(2) | priceToUsd }}</span>
        </div>
        <b-spinner
          small
          v-if="loadingInsurance"
          variant="light"
          label="Spinning"
        ></b-spinner>
      </button>
      <button
        class="quote-button quote-button--green"
        @click="selectedCarrier(true, carrier)"
      >
        <div class="quote-button__text" v-if="!loadingInsurance">
          <span class="ellipsis-text">{{ $t("carriers.bookWithInsurance") }}</span>
          <span>
            USD{{ (carrier.price + aditionalInsurance).toFixed(2) | priceToUsd }}
          </span>
        </div>
        <b-spinner
          small
          v-if="loadingInsurance"
          variant="light"
          label="Spinning"
        ></b-spinner>
      </button>
      <b-modal
        v-model="showModal"
        hide-header
        hide-footer
        size="lg"
        no-close-on-backdrop
        centered
      >
        <QuoteAcceptanceModal
          :insurance="insurance"
          :aditionalInsurance="aditionalInsurance"
          :showPickupPrice="showPickupPrice"
          :carrier="carrier"
          @closeModal="closeModal"
          @optionSelected="optionSelected"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import OptionsCarriers from "../../../../../../../Extend/OptionsCarriers";

export default {
  name: "OptionsPartialResponsive",
  mixins: [OptionsCarriers],
  data() {
    return {
      isCollapsed: "is-collapsed",
    };
  },
  methods: {
    collapse() {
      if (this.isCollapsed === "is-collapsed") {
        this.isCollapsed = "not-collapsed";
      } else {
        this.isCollapsed = "is-collapsed";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.option-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border: 1px solid rgb(163, 163, 163);
  border-radius: 15px;
  background: $color-white;
  padding: 5%;
  &__item {
    display: flex;
    text-align: start;
    color: $color-primary-company;
    font-weight: bold;
    &--restrictions {
      flex-direction: column;
    }
    p {
      font-weight: bold;
    }
    span {
      font-weight: bold;
    }
    &--percentage {
      font-weight: normal !important;
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 3%;
    &__description {
      p {
        margin: 0 !important;
      }
    }
  }
  &__book-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__collapse {
    width: 100%;
    border-top: 2px solid $color-gray-dark;
    margin-top: 1%;
    margin-bottom: 3%;
    padding: 5% 2% 0 2%;
    text-align: left;
    &__title-liability {
      display: flex;
      flex-direction: row;
      p {
        font-weight: 400;
        margin: 0px;
      }
    }
    &__warning {
      font-weight: normal;
    }
    &__restrictions {
      color: orange;
      text-decoration: underline;
      font-style: italic;
    }
  }
}
.option-container__item-text {
  display: flex;
  text-align: start;
  color: $color-primary-company;
}
.disabled-button {
  @include font-small-button-text;
  background-color: $color-gray;
}

.image-container {
  padding: 10px;
  &__image {
    max-width: 200px;
    width: 100%;
  }
}
.collapse-button {
  &__button {
    border: none;
    background: transparent;
  }
  &__icon {
    color: $color-primary-company;
    font-size: 2rem;
  }
}
.quote-button {
  width: 100%;
  min-width: 140px;
  margin: 5px auto;
  padding: 2% 3%;
  height: 40px;
  border-radius: 15px;
  border: none;
  @include font-small-button-text;
  font-weight: bold;
  display: flex;
  align-items: center;
  &--green {
    background: $color-carrier-quote-insurance-button;
    color: $color-carrier-quote-insurance-button-text;
  }
  &--blue {
    background: $color-carrier-quote-button;
    color: $color-white;
  }
  &__text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 62%;
    }
  }
}
.disabled-button {
  background-color: $color-disabled;
}
.collapse-button {
  transform: rotate(0deg);
  transition: 0.3s;
}
.collapse-button .not-collapsed {
  transform: rotate(90deg);
  transition: 0.3s;
}
.warning-alert-icon i {
  color: #e6bb6f;
  font-size: 23px;
}

// modal styles
::v-deep .modal-content {
  border: 1px solid #00b9cc;
  border-radius: 10px;
  background-color: $color-gray-light;
}
</style>
